import React, { useState } from 'react';
import { Button } from "@/components/ui/button";
import { Card, CardContent } from "@/components/ui/card";
import { MessageCircle, X } from "lucide-react";

const FloatingHelpButton = () => {
    const [isExpanded, setIsExpanded] = useState(false);

    return (
        <div className="fixed bottom-8 right-8 z-50">
            {!isExpanded ? (
                <Button
                    onClick={() => setIsExpanded(true)}
                    className="rounded-full px-6 py-2 bg-black hover:bg-gray-800 text-white"
                >
                    <MessageCircle className="mr-2 h-4 w-4" />
                    Contact
                </Button>
            ) : (
                <Card className="w-72 shadow-lg">
                    <CardContent className="p-4">
                        <div className="flex justify-between items-center mb-4">
                            <h3 className="font-semibold">Contact Us</h3>
                            <Button
                                variant="ghost"
                                size="sm"
                                onClick={() => setIsExpanded(false)}
                                className="h-8 w-8 p-0"
                            >
                                <X className="h-4 w-4" />
                            </Button>
                        </div>
                        <div className="flex justify-center">
                            <img
                                src="/images/stragiht_a_wechat.jpg"
                                alt="Wechat QR Code"
                                className="w-48 h-48"
                            />
                        </div>
                        <p className="text-sm text-center mt-4 text-gray-600">
                            Scan to contact our support team
                        </p>
                    </CardContent>
                </Card>
            )}
        </div>
    );
};

export default FloatingHelpButton;
