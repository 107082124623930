import React, {useEffect} from 'react';
import { FileText, LogOut, Settings, CreditCard, Bot } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { ScrollArea } from '@/components/ui/scroll-area';
import { useAuth } from '@/contexts/AuthContext';
import { useDispatch, useSelector} from "react-redux";
import {fetchUserProfile} from "@/redux/slices/userSlice";
import FloatingHelpButton from "@/components/FloatingHelpButton";
import {useNavigate, Link } from "react-router-dom";

const Layout = ({ children, showSidebar = true }) => {
    const dispatch = useDispatch();
    const { logout, isAuthenticated } = useAuth();
    const navigate = useNavigate();
    const userProfile = useSelector((state) => state.user.profile);
    const loading = useSelector((state) => state.user.loading);

    // Fetch user profile on mount and when auth state changes
    useEffect(() => {
        if (isAuthenticated) {
            dispatch(fetchUserProfile());
        }
    }, [dispatch, isAuthenticated]);

    const handleLogout = async () => {
        try {
            await logout();
        } catch (error) {
            console.error('Logout failed:', error);
        }
    };

    const handleSettingsClick = () => {
        navigate('/profile');
    };

    const handleCreditsClick = () => {
        // Add your navigation logic
        window.open('https://afh1lmpx0eddz0cs.mikecrm.com/zsjs2X3', '_blank', 'noopener,noreferrer');
    };

    // Function to refetch user profile (can be called from anywhere)
    const refreshUserProfile = () => {
        if (isAuthenticated) {
            dispatch(fetchUserProfile());
        }
    };

    return (
        <div className="min-h-screen bg-white">
            {/* Sidebar */}
            {showSidebar && (
                <div className="fixed left-0 top-0 w-52 h-full bg-black shadow-sm z-30 flex flex-col">
                    {/* Header */}
                    {/* Header */}
                    <Link
                        to="/"
                        className="flex-none h-16 flex items-center justify-center bg-black hover:bg-gray-800 transition-colors duration-200"
                    >
                        <h1 className="font-semibold text-lg text-white">Straight A</h1>
                    </Link>

                    {/* Scrollable Navigation Area */}
                    <div className="flex-1 flex flex-col min-h-0">
                        <ScrollArea className="flex-1">
                            <div className="p-3 space-y-2">
                                <Button
                                    variant="ghost"
                                    className="w-full justify-start text-sm text-white hover:bg-gray-800 hover:text-white"
                                    onClick={() => navigate('/detection/ai')}
                                >
                                    <Bot className="mr-2 h-4 w-4"/>
                                    AI Detection
                                </Button>
                                <Button
                                    variant="ghost"
                                    className="w-full justify-start text-sm text-white hover:bg-gray-800 hover:text-white"
                                    onClick={() => navigate('/rewrite')}
                                >
                                    <FileText className="mr-2 h-4 w-4"/>
                                    AI Humanizer
                                </Button>
                            </div>
                        </ScrollArea>
                    </div>

                    {/* User Section - Fixed at Bottom */}
                    {isAuthenticated && (
                        <div className="flex-none p-3 border-t border-gray-800 space-y-3">
                            {/* User Email and Settings */}
                            <div className="flex items-center justify-between px-2">
                                <div className="text-sm text-gray-400 truncate flex-1">
                                    {userProfile?.email || 'Email'}
                                </div>
                                <Button
                                    variant="ghost"
                                    size="icon"
                                    onClick={handleSettingsClick}
                                    className="h-8 w-8 text-gray-400 hover:text-white hover:bg-gray-800"
                                >
                                    <Settings className="h-4 w-4" />
                                </Button>
                            </div>

                            {/* Credits Display */}
                            <div className="flex items-center justify-between px-2">
                                <div className="flex items-center text-gray-400">
                                    <span className="text-sm">Credits </span>
                                    <span className="text-sm font-medium text-white ml-1">
                                        {loading ? "Loading..." : (userProfile?.credit || 0)}
                                    </span>
                                </div>
                                <Button
                                    variant="ghost"
                                    size="icon"
                                    onClick={handleCreditsClick}
                                    className="h-8 w-8 text-gray-400 hover:text-white hover:bg-gray-800"
                                >
                                    <CreditCard className="h-4 w-4" />
                                </Button>
                            </div>

                            {/* Logout Button */}
                            <Button
                                variant="ghost"
                                onClick={handleLogout}
                                className="w-full justify-start text-sm text-white hover:bg-gray-800 hover:text-white"
                            >
                                <LogOut className="mr-2 h-4 w-4" />
                                Logout
                            </Button>
                        </div>
                    )}
                </div>
            )}

            {/* Main content */}
            <div className={showSidebar ? "pl-52" : ""}>
                <div className="p-8">
                    {children}
                </div>
            </div>

            {/* Floating Help Button */}
            <FloatingHelpButton />
        </div>
    );
};

export default Layout;
