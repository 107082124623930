import React from 'react';
import { Shield, CheckCircle, Clock, Lock } from 'lucide-react';
import {useNavigate} from "react-router-dom";
import FloatingHelpButton from "@/components/FloatingHelpButton";

const LandingPage = () => {
    const navigate = useNavigate();

    const handleLogIn = () => {
        navigate('/login');
    };

    const scrollToFeatures = () => {
        const featuresSection = document.getElementById('features');
        featuresSection?.scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <div className="min-h-screen bg-black">
            {/* Navigation */}
            <nav className="bg-black border-b border-gray-800">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
                    <div className="relative flex items-center justify-between">
                        <div className="flex-shrink-0">
                            <div className="font-bold text-xl text-pink-500">Straight-A</div>
                        </div>
                        <div className="hidden sm:flex sm:items-center sm:space-x-4">
                            <button
                                onClick={scrollToFeatures}
                                className="text-gray-300 hover:text-white px-3 py-2">
                                Features
                            </button>
                            {/*<button className="text-gray-300 hover:text-white px-3 py-2">How It Works</button>*/}
                            <button
                                onClick={handleLogIn}
                                className="bg-pink-500 text-white px-4 py-2 rounded-lg hover:bg-pink-600 transition duration-300 ease-in-out ml-4"
                            >
                                Log In
                            </button>
                        </div>
                        {/* Mobile menu button */}
                        <div className="flex sm:hidden">
                            <button className="text-gray-300 hover:text-white px-2 py-1">
                                <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16m-16 6h16" />
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </nav>

            {/* Hero Section */}
            <div className="bg-black">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12 sm:py-20">
                    <div className="flex flex-col md:flex-row items-center justify-between">
                        <div className="md:w-1/2 mb-8 md:mb-0 md:pr-8 lg:pr-12">
                            <h1 className="text-3xl sm:text-4xl md:text-5xl font-bold text-white mb-6">
                                Verify and Humanize Your Work with Confidence
                            </h1>
                            <p className="text-lg sm:text-xl text-gray-400 mb-8">
                                Quick, accurate AI content detection for your assignments.
                                Humanize your essays and papers in seconds.
                            </p>
                            <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4">
                                <button
                                    onClick={handleLogIn}
                                    className="w-full sm:w-auto bg-pink-500 text-white px-6 py-3 rounded-lg text-lg hover:bg-pink-600 transition duration-300">
                                    Check Assignment Now
                                </button>
                                <button
                                    className="w-full sm:w-auto border border-gray-700 px-6 py-3 rounded-lg text-lg text-gray-300 hover:border-gray-600 hover:text-white transition duration-300">
                                    Learn More
                                </button>
                            </div>
                        </div>
                        <div className="md:w-1/2">
                            <div className="bg-gray-900 rounded-lg p-4 sm:p-8">
                                <img
                                    src="/images/landing_page_screenshot.png"
                                    alt="AI Detection Demo"
                                    className="w-full rounded-lg shadow-lg"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Features Section */}
            <div id="features" className="bg-black py-12 sm:py-20">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="text-center mb-12">
                        <h2 className="text-3xl font-bold text-white mb-4">
                            Why Students Trust Us
                        </h2>
                        <p className="text-lg sm:text-xl text-gray-400">
                            Simple, reliable, and designed for academic success
                        </p>
                    </div>

                    <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-6">
                        <FeatureCard
                            icon={<Shield className="w-8 h-8 text-pink-500"/>}
                            title="High Accuracy"
                            description="Advanced AI detection algorithms ensure reliable results"
                        />
                        <FeatureCard
                            icon={<Clock className="w-8 h-8 text-pink-500"/>}
                            title="Instant Results"
                            description="Get your humanized results in seconds"
                        />
                        <FeatureCard
                            icon={<Lock className="w-8 h-8 text-pink-500"/>}
                            title="100% Private"
                            description="Your assignments stay confidential and secure"
                        />
                        <FeatureCard
                            icon={<CheckCircle className="w-8 h-8 text-pink-500"/>}
                            title="Easy to Use"
                            description="Simple paste-and-check interface for quick verification"
                        />
                    </div>
                </div>
            </div>

            {/* CTA Section */}
            <div className="bg-gray-900 text-white py-12 sm:py-16">
                <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
                    <h2 className="text-3xl font-bold mb-4">
                        Ready to Verify Your Assignment?
                    </h2>
                    <p className="text-lg sm:text-xl text-gray-400 mb-8">
                        Join thousands of students who trust Straight-A for their academic integrity
                    </p>
                    <button
                        onClick={handleLogIn}
                        className="w-full sm:w-auto bg-pink-500 text-white px-8 py-3 rounded-lg text-lg font-semibold hover:bg-pink-600 transition duration-300 ease-in-out"
                    >
                        Start Free Trial
                    </button>
                </div>
            </div>

            {/* Footer */}
            <footer className="bg-black py-6 border-t border-gray-800">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <p className="text-center text-gray-400 text-sm">
                        © 2024 StraightA. All rights reserved.
                    </p>
                </div>
            </footer>

            {/* Floating Help Button */}
            <FloatingHelpButton />
        </div>
    );
};

const FeatureCard = ({ icon, title, description }) => {
    return (
        <div className="bg-gray-900 p-6 rounded-lg border border-gray-800">
            <div className="mb-4">{icon}</div>
            <h3 className="text-xl font-semibold text-white mb-2">{title}</h3>
            <p className="text-gray-400">{description}</p>
        </div>
    );
};

export default LandingPage;
