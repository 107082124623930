import React, {useState} from 'react';
import {Check, Copy, ExternalLink, FileText, RefreshCw, Loader2, Info} from 'lucide-react';
import {Button} from '@/components/ui/button';
import {Textarea} from '@/components/ui/textarea';
import {Card, CardContent, CardHeader, CardTitle} from '@/components/ui/card';
import {Alert, AlertDescription, AlertTitle} from "@/components/ui/alert";
import FloatingHelpButton from "@/components/FloatingHelpButton";
import Layout from "@/components/Layout";
import api from "@/utils/api";
import { useCredit } from '@/hooks/useCredit';

const SUBSCRIPTION_CRM_URL = 'https://afh1lmpx0eddz0cs.mikecrm.com/zsjs2X3'
const SUCCESS_CODE = 10000;
const MAX_CHARS_PER_WORD = 30; // Maximum characters to consider as a single word
const MAX_WORD_COUNT = 500; // Maximum allowed words

const AiRephraser = () => {
  const [inputText, setInputText] = useState('');
  const [rephrasedText, setRephrasedText] = useState('');
  const [wordCount, setWordCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [error, setError] = useState(null);
  const { refreshCredit } = useCredit();

  const handleTextChange = (e) => {
    const text = e.target.value;
    const newWordCount = countWords(text);

    if (newWordCount > MAX_WORD_COUNT) {
      setError(`Text exceeds maximum word limit of ${MAX_WORD_COUNT} words`);
    } else {
      setError(null);
    }

    setInputText(text);
    setWordCount(newWordCount);
  };

  const handleGetCode = () => {
    window.open(SUBSCRIPTION_CRM_URL, '_blank', 'noopener,noreferrer');
  };

  // const handleFileUpload = async (e) => {
  //   const file = e.target.files[0];
  //   if (file) {
  //     try {
  //       setIsLoading(true);
  //       if (file.type === 'application/pdf') {
  //         const formData = new FormData();
  //         formData.append('file', file);
  //
  //         const response = await fetch(BACKEND_URL + '/orchestrator/parse_file', {
  //           method: 'POST',
  //           body: formData,
  //         });
  //         const responseData = await response.json();
  //         console.log(responseData)
  //         setInputText(responseData.text);
  //         setWordCount(countWords(responseData.text));
  //       } else {
  //         const text = await readFileContent(file);
  //         setInputText(text);
  //         setWordCount(countWords(text));
  //       }
  //     } catch (error) {
  //       console.error('Error reading file:', error);
  //       // You might want to show an error message to the user here
  //     } finally {
  //       setIsLoading(false);
  //     }
  //   }
  // };

  const readFileContent = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => resolve(event.target.result);
      reader.onerror = (error) => reject(error);
      reader.readAsText(file);
    });
  };

  const countWords = (text) => {
    if (!text || !text.trim()) return 0;
    // First, normalize whitespace and trim the text
    const normalizedText = text.trim().replace(/\s+/g, ' ');
    // Split the text into potential words
    const words = normalizedText.split(' ');
    // Process each word to handle edge cases
    return words.reduce((acc, word) => {
      if (!word) return acc;

      // If word is longer than MAX_CHARS_PER_WORD (30 chars),
      // it might be multiple words stuck together
      if (word.length > MAX_CHARS_PER_WORD) {
        // 1. Split by capital letters (CamelCase)
        // "HelloWorld" -> ["Hello", "World"]
        const camelCaseSplit = word.split(/(?=[A-Z])/g);

        // 2. Split by numbers and punctuations
        // "thisIs1Word" -> ["thisIs", "1", "Word"]
        const withNumbersSplit = camelCaseSplit
            .flatMap(part => part.split(/(?<=\d)(?=[a-zA-Z])|(?<=[a-zA-Z])(?=\d)/g));

        // 3. Handle any remaining long parts by breaking them into chunks
        const finalParts = withNumbersSplit.flatMap(part => {
          if (part.length > MAX_CHARS_PER_WORD) {
            // Break very long strings into chunks of MAX_CHARS_PER_WORD
            const chunks = [];
            for (let i = 0; i < part.length; i += MAX_CHARS_PER_WORD) {
              chunks.push(part.slice(i, i + MAX_CHARS_PER_WORD));
            }
            return chunks;
          }
          return [part];
        });

        // Add the number of parts to our word count
        return acc + finalParts.length;
      }

      // If it's a normal word, just add 1 to our count
      return acc + 1;

    }, 0);
  };

  const handleRephrase = async () => {
    if (!inputText.trim()) {
      setError('Please enter text for humanization.')
      return;
    }

    setIsLoading(true);
    setError(null)

    try {
      const response = await api.post('/humanize/run', {
        ai_content: inputText,
      });

      /*
        Format:
        {
          code: xxx,
          message: xxx,
          body: {}
        }
       */
      const data = response.data;
      if (data.code !== SUCCESS_CODE) {
        throw new Error(data.message)
      }
      const humanized_content = data.body.content;
      setRephrasedText(humanized_content);

      refreshCredit();
    } catch (error) {
      console.error('Error rephrasing text:', error);
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(rephrasedText);
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000);
    } catch (error) {
      console.error('Failed to copy text: ', error);
      setError('Failed to copy text.');
    }
  };

  return (
      <Layout>
        <Card className="max-w-6xl mx-auto">
          <CardHeader>
            <CardTitle className="text-center text-2xl">
              AI Humanizer
            </CardTitle>
          </CardHeader>
          <CardContent className="space-y-6">
            <Alert variant="info" className="bg-pink-50">
              <div className="px-4">
                <AlertTitle className="text-base font-medium mb-2">How to Use</AlertTitle>
                <AlertDescription>
                  <ol className="list-decimal ml-4 space-y-2">
                    <li className="pl-2">Each humanization takes <span className="font-medium">5</span> credits.
                    </li>
                    <li className="pl-2">Click <span className="font-medium">Get Credits</span> to go to the checkout page.
                      After purchasing, you can check your purchase history in user profile page.
                    </li>
                    <li className="pl-2">Click <span className="font-medium">Rephrase</span>. The rewriting process may
                      take some time, please be patient.
                    </li>
                    <li className="pl-2">Click the <span className="font-medium">Copy</span> button to copy humanized
                      text.
                    </li>
                    <li className="pl-2">If you encounter any issues, please contact our customer service.</li>
                  </ol>
                </AlertDescription>
              </div>
            </Alert>

            <div className="flex flex-col space-y-4">
              {error && (
                  <Alert variant="destructive">
                    <AlertTitle>Error</AlertTitle>
                    <AlertDescription>{error}</AlertDescription>
                  </Alert>
              )}
              <div className="flex space-x-4">
                <div className="w-1/2 space-y-2">
                  <span className="font-semibold">Original Text</span>
                  <Textarea
                      placeholder="Enter your text here..."
                      value={inputText}
                      onChange={handleTextChange}
                      className="h-64"
                  />
                  <div className="space-y-2">
                    <div className="flex items-center justify-between">
                      <div className="flex items-center space-x-2">
                        <FileText className="h-4 w-4"/>
                        <span className={wordCount > MAX_WORD_COUNT ? 'text-red-500' : ''}>
                          Word count: {wordCount} / {MAX_WORD_COUNT}
                        </span>
                      </div>
                    </div>
                    <div className="flex space-x-2">
                      <Button
                          variant="outline"
                          size="default"
                          onClick={handleGetCode}
                          className="whitespace-nowrap"
                      >
                        <ExternalLink className="mr-2 h-4 w-4"/>
                        Get Credits
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="w-1/2 space-y-2">
                  <span className="font-semibold">Rephrased Text</span>
                  <div className="relative">
                    <Textarea
                        value={rephrasedText}
                        readOnly
                        className="h-64"
                        placeholder="Rephrased text will appear here..."
                    />
                    {isLoading && (
                        <div className="absolute inset-0 flex items-center justify-center bg-background/50">
                          <Loader2 className="h-8 w-8 animate-spin text-primary"/>
                        </div>
                    )}
                  </div>
                  <div className="flex justify-end mt-2">
                    <Button onClick={handleCopy} variant="outline" size="sm">
                      {isCopied ? <Check className="mr-2 h-4 w-4"/> : <Copy className="mr-2 h-4 w-4"/>}
                      {isCopied ? 'Copied!' : 'Copy'}
                    </Button>
                  </div>
                </div>
              </div>
              <div className="flex justify-center mt-4">
                <Button
                    onClick={handleRephrase}
                    disabled={isLoading}
                    className="px-8"
                >
                  {isLoading ? (
                      <>
                        <Loader2 className="mr-2 h-4 w-4 animate-spin"/>
                        Rephrasing...
                      </>
                  ) : (
                      <>
                        <RefreshCw className="mr-2 h-4 w-4"/>
                        Rephrase
                      </>
                  )}
                </Button>
              </div>
            </div>
            <div className="mt-8 pt-4 border-t text-center text-sm text-gray-500">
              <p>Copyright © 2024 StraightA</p>
            </div>
          </CardContent>
        </Card>
      </Layout>
  );
};

export default AiRephraser;
