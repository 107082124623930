import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from "@/utils/api";

// Async thunk for fetching user profile
export const fetchUserProfile = createAsyncThunk(
    'user/fetchProfile',
    async (_, { rejectWithValue }) => {
        try {
            const response = await api.get('/user/profile/');
            return response.data.body.user;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

const userSlice = createSlice({
    name: 'user',
    initialState: {
        profile: null,
        loading: false,
        error: null,
        lastFetchedAt: null,
    },
    reducers: {
        updateCredits: (state, action) => {
            if (state.profile) {
                state.profile.credit = action.payload;
            }
        },
        setUserProfile: (state, action) => {
            state.profile = action.payload;
            state.lastFetchedAt = new Date().toISOString();
            state.loading = false;
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchUserProfile.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchUserProfile.fulfilled, (state, action) => {
                state.loading = false;
                state.profile = action.payload;
                state.lastFetchedAt = new Date().toISOString();
            })
            .addCase(fetchUserProfile.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    },
});

export const { updateCredits, setUserProfile } = userSlice.actions;
export default userSlice.reducer;
