import React from "react";
import { cn } from "@/lib/utils";
import { AlertCircle, Info } from "lucide-react";

const Alert = ({ children, className, variant = "default", ...props }) => {
    return (
        <div
            role="alert"
            className={cn(
                "relative w-full rounded-lg border p-4",
                variant === "default" && "bg-background text-foreground",
                variant === "destructive" &&
                "border-destructive/50 text-destructive dark:border-destructive bg-destructive/10",
                className
            )}
            {...props}
        >
            {variant === "destructive" && (
                <AlertCircle className="absolute right-4 top-4 h-4 w-4" />
            )}
            {variant === "default" && (
                <Info className="absolute right-4 top-4 h-4 w-4" />
            )}
            {children}
        </div>
    );
};

const AlertTitle = ({ className, children, ...props }) => {
    return (
        <h5
            className={cn(
                "mb-1 font-medium leading-none tracking-tight",
                className
            )}
            {...props}
        >
            {children}
        </h5>
    );
};

const AlertDescription = ({ className, children, ...props }) => {
    return (
        <div
            className={cn(
                "text-sm [&_p]:leading-relaxed",
                className
            )}
            {...props}
        >
            {children}
        </div>
    );
};

export { Alert, AlertTitle, AlertDescription };
