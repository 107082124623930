import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { fetchUserProfile } from '@/redux/slices/userSlice';

export const useCredit = () => {
    const dispatch = useDispatch();

    const refreshCredit = useCallback(() => {
        dispatch(fetchUserProfile());
    }, [dispatch]);

    return { refreshCredit };
};

export default useCredit;
